<template>
  <div>
    <!-- Breadcrumbs -->
    <Breadcrumb :nameNav="nameNav" :itemFilter="itemFilter" :searchQuery="searchQuery"
                @itemFilterSearch="addItemFilterSearch"/>

    <!-- Toastification loading content export -->
    <toastification-loading-content ref="loadingToast"/>

    <filters :message-filter.sync="messageFilter"
             :message-options="messageOptions"
             :user-filter.sync="userFilter"
             :user-options="userOptions"
             :tenant-options="tenantOptions"
             :tenant-filter.sync="tenantFilter"
             :action-filter.sync="actionFilter"
             :action-options="actionOptions"
             :end-date.sync="endDate"
             :level-filter.sync="levelFilter"
             :level-options="levelOptions"
             :start-date.sync="startDate"
             :resource-filter.sync="resourseFilter"
             :resource-options.sync="resourseOptions"
    />

    <!-- Table Container Card -->
    <b-card class="m-2" no-body>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col cols="12" md="6"
                 class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label class="text-capitalize">{{ $t("table.show") }}</label>
            <v-select v-model="perPage"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="perPageOptions"
                      :clearable="false" class="per-page-selector d-inline-block mx-50"/>
            <label class="text-capitalize">{{ $t("table.entries") }}</label>
          </b-col>
        </b-row>
      </div>

      <b-table ref="refListTable" class="position-relative" :items="fetchList" responsive
               :fields="tableColumns"
               primary-key="id" :sort-by.sync="sortBy" show-empty
               :empty-text="$t('no_record_found')"
               :sort-desc.sync="isSortDirDesc">
        <template #head()="data">
          <span> {{ $t(data.label) }}</span>
        </template>

        <template #cell(diff)="data">
          <div style="max-height: 200px; overflow-y: auto;">
            <Marked :jsonObject="data.value" :change.sync="change" :key="change"/>
          </div>
        </template>
        <template #cell(level)="data">
          <span>{{ getLevelText(data.value) }}</span>
        </template>

        <template #cell(tenant_cat)="data">
          <div style="max-height: 200px; overflow-y: auto;"><span>{{
              getName(data.value)
            }}</span></div>
        </template>

      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6"
                 class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted text-capitalize">{{
                $t("table.showing", {
                  from: dataMeta.from,
                  to: dataMeta.to,
                  of: dataMeta.of,
                })
              }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6"
                 class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <Pagination :totalList="totalList" :currentPage="currentPage"
                        @updatePagination="update" :perPage="perPage">
            </Pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BButton,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import {
  ref,
  computed
} from "@vue/composition-api";
import useList from "./useList";
import {
  useToast
} from "vue-toastification/composition";
import ToastificationContent
  from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import {
  useUtils as useI18nUtils
} from "@core/libs/i18n";
import Breadcrumb from "@/components/Breadcrumb.vue";
import Marked from "./Marked.vue";
import ToastificationLoadingContent
  from "@core/components/toastification/ToastificationLoadingContent.vue";
import Pagination from "@/components/Pagination.vue";
import Filters from "./Filters.vue";

export default {
  components: {
    Filters,
    Marked,
    Pagination,
    Breadcrumb,
    ToastificationLoadingContent,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },

  setup(_, context) {
    const getName = (str) => {
      try {
        const obj = JSON.parse(str);
        if (Array.isArray(obj)) {
          let format = JSON.stringify(obj.map(elem => elem.name)).replace(/["\[\]]/g, "")
            .replace(/_/g, " ");
          return format
        } else {
          console.error('The property value is not an array in the JSON');
          return [];
        }
      } catch (error) {
        console.error('Error parsing JSON string:', error);
        return [];
      }
    };

    const getLevelText = (data) => {
      switch (data) {
        case 0:
          return "EMERGENCY";
        case 1:
          return "ALERT";
        case 2:
          return "CRITICAL";
        case 3:
          return "ERROR";
        case 4:
          return "WARNING";
        case 5:
          return "NOTICE";
        case 6:
          return "INFO";
        case 7:
          return "DEBUG";

      }
    }
    //start pagination
    const update = (data) => {
      currentPage.value = data;
    };
    //end pagination

    // start export
    const loadingToast = ref(null);
    const action = ref("list");
    const {
      t
    } = useI18nUtils();
    const trans = {
      "messages.export.download": t("messages.export.download"),
      "messages.export.Generating_file": t("messages.export.Generating_file"),
      "messages.error.server_error": t("messages.error.server_error"),
      "messages.delete.title": t("messages.delete.title"),
      "messages.delete.body": t("messages.delete.body"),
      "actions.delete": t("actions.delete"),
      "actions.cancel": t("actions.cancel"),
    };

    // start breadcrumbs
    const itemFilter = ref();
    const addItemFilter = (data) => {
      itemFilter.value = data;
    };
    const addItemFilterSearch = (data) => {
      data.forEach((element) => {
        if (nameNav.value === element.text) {
          searchQuery.value = element.searchQuery;
        }
      });
    };
    const nameNav = ref("log_microservice.title");
    // end breadcrumbs

    const toast = useToast();
    const {
      resourseOptions,
      resourseFilter,
      startDate,
      endDate,
      levelOptions,
      levelFilter,
      actionFilter,
      actionOptions,
      tenantOptions,
      tenantFilter,
      messageOptions,
      messageFilter,
      change,
      fetchList,
      tableColumns,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      formatDateAssigned,
      userOptions,
      userFilter,
    } = useList();

    return {
      resourseOptions,
      resourseFilter,
      startDate,
      endDate,
      getName,
      getLevelText,
      levelOptions,
      levelFilter,
      actionFilter,
      actionOptions,
      tenantOptions,
      tenantFilter,
      userOptions,
      userFilter,
      messageOptions,
      messageFilter,
      change,
      update,
      loadingToast,
      action,
      itemFilter,
      addItemFilter,
      addItemFilterSearch,
      nameNav,
      fetchList,
      tableColumns,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      formatDateAssigned,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";

.per-page-selector {
  width: 90px;
}
</style>

