<template>
  <div>
    <pre>
      <code
        class="language-html" v-html="highlightedCode">
      </code>
    </pre>
  </div>
</template>

<script>
import 'prismjs';
import 'prismjs/themes/prism.css';
import 'prismjs/components/prism-json';

export default {
  name: 'Marked',
  props: {
    jsonObject: {
      type: Object
    },
  },
  data() {
    return {
      jsonString: this.$props.jsonObject.old_data,
      highlightedCode: '',
      columnsErrors: this.$props.jsonObject.diff,
      errorData: [],
      resource: this.$props.jsonObject.resource,
    };
  },

  mounted() {
    if (this.columnsErrors !== null) {
      this.highlightCode();
    } else {
      this.highlightedCode = this.formatObjectCreate();
    }
  },
  methods: {
    highlightCode() {
      const jsonData = this.jsonString;
      const deleteColumns = this.columnsErrors;
      const newData = {};
      deleteColumns.forEach(elemento => {
        const keyToDelete = elemento.path[0];
        delete jsonData[keyToDelete];

      });
      this.highlightedCode = this.formatObjectToDivs(newData);
    },

    formatObjectToDivs(obj) {
      let errores = "";
      this.columnsErrors.forEach(function (elemento) {
        let index = elemento.path.length - 1;
        errores += `<div class="new">Dato actualizado ${this.parseTranslate(elemento.path[index])}": "${elemento.lhs}"</div>`;
        errores += `<div class="old">Dato anterior ${this.parseTranslate(elemento.path[index])}": "${elemento.rhs}"</div>`;
      }.bind(this));
      this.errorData = errores
      const divs = Object.entries(obj).map(([key, value]) => {
        if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
          return `<div>"${this.parseTranslate(key)}": {${JSON.stringify(value)}</div>`;
        } else {
          return `<div>"${this.parseTranslate(key)}": ${JSON.stringify(value)}</div>`;
        }
      }).join("");

      return `<div>${errores + divs}</div>`;
    },

    formatObjectCreate() {
      let data = this.jsonObject.data;
      console.log(this.resource)
      const convertToDivs = (obj) => {
        let sign = "";
        sign = this.jsonObject.action === "CREATE" ? "Dato actualizado"
          : (this.jsonObject.action === "DELETE" ? "Dato anterior"
            : sign);
        return Object.entries(obj).map(([key, value]) => {
          if (Array.isArray(value)) {
            const arrayDivs = value.map((item, index) => `<div>${convertToDivs(item)}</div>`).join("");
            return `<div>${sign} "${this.parseTranslate(key)}":<div class="nested">${arrayDivs}</div></div>`;
          } else if (typeof value === 'object' && value !== null) {
            return `<div>${sign} "${this.parseTranslate(key)}":<div class="nested">${convertToDivs(value)}</div></div>`;
          } else {
            return `<div>${sign} "${this.parseTranslate(key)}": ${JSON.stringify(value)}</div>`;
          }
        }).join("");
      };
      const divs = convertToDivs(data);
      return this.jsonObject.action === "CREATE"
        ? `<div class="new">${divs}</div>`
        : (this.jsonObject.action === "DELETE"
          ? `<div class="old">${divs}</div>`
          : null);
    },

    parseTranslate(key) {
      let resourceData = key;
      switch (this.resource) {
        case "USER":
          switch (key) {
            case "master_tenant":
              resourceData = this.$tc("master_tenants.title", 2)
              break;
            case "tenant":
              resourceData = this.$tc("tenants.title", 2)
              break;
            case "id_profile":
              resourceData = this.$tc("profiles.title", 2)
              break;
            case "type_alarm":
              resourceData = this.$t("intelexvision_event.type_alarm")
              break;
            case "permission":
              resourceData = this.$t("profiles.permissions")
              break;
            default:
              resourceData = this.$tc(`users.${key}`, 1)
          }
          break;
        case "MUNICIPALITY":
          switch (key) {
            default:
              resourceData = this.$tc(`municipality.${key}`, 1)
          }
          break;
        case "ALARM_COMMENT":
          switch (key) {
            default:
              resourceData = this.$tc(`alarm_comment.${key}`, 1)
          }
          break;
        case "ALARM_LEVEL":
          switch (key) {
            default:
              resourceData = this.$tc(`alarm_level.${key}`, 1)
          }
          break;
        case "ALARM_PURGE":
          switch (key) {
            default:
              resourceData = this.$tc(`alarm_purge.${key}`, 1)
          }
          break;
        case "BRIDGE":
          switch (key) {
            default:
              resourceData = this.$tc(`bridge.${key}`, 1)
          }
          break;
        case "BRIDGE_ACCOUNT":
          switch (key) {
            default:
              resourceData = this.$tc(`bridge.${key}`, 1)
          }
          break;
        case "BRIDGE_MASTER_ACCOUNT":
          switch (key) {
            default:
              resourceData = this.$tc(`bridge.${key}`, 1)
          }
          break;
        case "ACCESS_DEVICE":
          switch (key) {
            case "master_tenant":
              resourceData = this.$tc("master_tenants.title", 2)
              break;
            case "tenant":
              resourceData = this.$tc("tenants.title", 2)
              break;
            case "id_profile":
              resourceData = this.$tc("profiles.title", 2)
              break;
            case "type_alarm":
              resourceData = this.$t("intelexvision_event.type_alarm")
              break;
            case "permission":
              resourceData = this.$t("profiles.permissions")
              break;
            default:
              resourceData = this.$tc(`access_devices.${key}`, 1)
          }
          break;
        case "GROUP":
          switch (key) {
            case "master_tenant":
              resourceData = this.$tc("master_tenants.title", 2)
              break;
            case "tenant":
              resourceData = this.$tc("tenants.title", 2)
              break;
            case "id_profile":
              resourceData = this.$tc("profiles.title", 2)
              break;
            case "type_alarm":
              resourceData = this.$t("intelexvision_event.type_alarm")
              break;
            case "permission":
              resourceData = this.$t("profiles.permissions")
              break;
            default:
              resourceData = this.$tc(`group.${key}`, 1)
          }
          break;
        default:

      }
      return resourceData;
    }
  },
};
</script>
<style>
.new {
  background-color: green;
  color: white;
  opacity: .5;
}

.old {
  background-color: red;
  color: white;
  opacity: .5;
}
</style>
