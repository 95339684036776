<template>
  <b-card no-body class="m-2">
    <b-card-header class="pb-50 text-capitalize">
      <h5>{{ $t('filter') }}</h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col cols="3" md="3" class="mb-md-0 mb-2">
          <label class="text-capitalize">
            {{ $t('log_microservice.log_message') }}
          </label>
          <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :value="messageFilter" :options="messageOptions"
                    class="w-100" multiple :reduce="val => val.value"
                    @input="(val) => $emit('update:messageFilter', val)"/>
        </b-col>

        <b-col cols="3" md="3" class="mb-md-0 mb-2">
          <label class="text-capitalize">
            {{ $t('log_microservice.user_id') }}
          </label>
          <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :value="userFilter" :options="userOptions"
                    class="w-100" multiple :reduce="val => val.label"
                    @input="(val) => $emit('update:userFilter', val)"/>
        </b-col>

        <b-col cols="3" md="3" class="mb-md-0 mb-2">
          <label class="text-capitalize">
            {{ $t('log_microservice.tenant_id') }}
          </label>
          <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :value="tenantFilter" :options="tenantOptions"
                    class="w-100" multiple :reduce="val => val.value"
                    @input="(val) => $emit('update:tenantFilter', val)"/>
        </b-col>

        <b-col cols="3" md="3" class="mb-md-0 mb-2">
          <label class="text-capitalize">
            {{ $t('log_microservice.action') }}
          </label>
          <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :value="actionFilter" :options="actionOptions"
                    class="w-100" multiple :reduce="val => val.value"
                    @input="(val) => $emit('update:actionFilter', val)"/>
        </b-col>

        <b-col cols="3" md="3" class="mb-md-0 mb-2">
          <label class="text-capitalize">
            {{ $t('log_microservice.level') }}
          </label>
          <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :value="levelFilter" :options="levelOptions"
                    class="w-100" multiple :reduce="val => val.value"
                    @input="(val) => $emit('update:levelFilter', val)"/>
        </b-col>

        <b-col cols="3" md="3" class="mb-md-0 mb-2">
          <label class="text-capitalize">
            {{ $t('log_microservice.resource') }}
          </label>
          <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :value="resourceFilter" :options="resourceOptions"
                    class="w-100" :reduce="val => val.value"
                    @input="(val) => $emit('update:resourceFilter', val)"/>
        </b-col>

        <b-col cols="3" md="3" class="mb-md-0 mb-2">
          <label class="text-capitalize">{{ $t('log_microservice.start_date') }}</label>
          <div class="input-group">
            <flat-pickr :value="startDate" class="form-control"
                        @input="(val) => dateStarClick(val)"
                        :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }"
                        :style="dateStartRequired ? 'border: solid red 1px' : ''"
            />
            <div class="input-group-append">
              <button @click="handleClick()"
                      class="btn btn-outline-primary"
                      type="button">
                x
              </button>
            </div>
          </div>
          <div class="errorText "
               v-show="dateStartRequired">
            {{ $t('log_microservice.The_start_date_value_is_required_for_filter_by_date') }}
          </div>
        </b-col>

        <b-col cols="3" md="3" class="mb-md-0 mb-2">
          <label class="text-capitalize">{{ $t('log_microservice.end_date') }}</label>
          <div class="input-group">
            <flat-pickr :value="endDate" class="form-control"
                        @input="(val) => dateEndClick(val)"
                        :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }"
                        :style="dateEndRequired ? 'border: solid red 1px' : ''"
            />
            <div class="input-group-append">
              <button @click="handleClick()"
                      class="btn btn-outline-primary"
                      type="button">
                x
              </button>
            </div>
          </div>
          <div class="errorText "
               v-show="dateEndRequired">           {{
              $t('log_microservice.The_end_date_value_is_required_to_filter_by_date')
            }}
          </div>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>

import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BButton,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    vSelect,
    flatPickr
  },
  props: {
    messageOptions: {
      type: Array,
      default: null,
    },
    messageFilter: {
      type: Array,
      default: null,
    },
    userOptions: {
      type: Array,
      default: null,
    },
    userFilter: {
      type: Array,
      default: null,
    },
    tenantOptions: {
      type: Array,
      default: null,
    },
    tenantFilter: {
      type: Array,
      default: null,
    },
    actionOptions: {
      type: Array,
      default: null,
    },
    actionFilter: {
      type: Array,
      default: null,
    },
    levelOptions: {
      type: Array,
      default: null,
    },
    levelFilter: {
      type: Array,
      default: null,
    },
    startDate: {
      type: String,
      default: null,
    },
    endDate: {
      type: String,
      default: null
    },
    resourceOptions: {
      type: Array,
      default: null,
    },
    resourceFilter: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      dateStartRequired: false,
      dateEndRequired: false,
      dateStartComponent: null,
      dateEndComponent: null,
    };
  },
  methods: {
    verification() {
      this.dateEndRequired = this.dateEndComponent == null;
      this.dateStartRequired = this.dateStartComponent == null;

      if (!this.dateEndRequired && !this.dateStartRequired) {
        this.$emit('update:startDate', this.dateStartComponent)
        this.$emit('update:endDate', this.dateEndComponent)
      }
    },

    handleClick() {
      this.$emit('update:startDate', null);
      this.dateStartRequired = false;
      this.dateStartComponent = null
      this.$emit('update:endDate', null);
      this.dateEndRequired = false;
      this.dateEndComponent = null
    },

    dateStarClick(val) {
      if (val !== "") {
        this.dateStartComponent = val
        this.verification()
      }
    },
    dateEndClick(val) {
      if (val !== "") {
        this.dateEndComponent = val
        this.verification()
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.pagination {
  display: flex;
  justify-content: space-around;
  margin: 0.25rem 0.25rem 0;
}

.pagination button {
  flex-grow: 1;

  &:first-child {
    margin-right: 5px;
  }
}

.pagination button:hover {
  cursor: pointer;
}

.errorInput {
  border: solid red 1px
}

.errorText {
  color: red;
  font-size: 12px
}
</style>
