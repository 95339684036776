import {ref, computed, watch} from '@vue/composition-api'
import {useToast} from 'vue-toastification/composition'
import ToastificationContent
  from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment-timezone'
import store from '@/store'
import deepEqual from 'deep-equal';
import deepDiff from 'deep-diff';
import {parse, addHours, format} from 'date-fns';

export default function useList() {
  store.dispatch('log_messages/getAll')
  store.dispatch('user/getAll')
  store.dispatch('tenant/getAll')
  const userData = JSON.parse(localStorage.getItem('userData'))
  const refListTable = ref(null)
  const toast = useToast()
  const startDate = ref(null)
  const endDate = ref(null)
  const messageOptions = computed(() => store.getters['log_messages/getSelectedItems'])
  const messageFilter = ref(null)
  const userOptions = computed(() => store.getters['user/getSelectedItems'])
  const userFilter = ref(null)
  const tenantOptions = computed(() => store.getters['tenant/getSelectedItems'])
  const tenantFilter = ref(null)
  const actionFilter = ref(null)

  const actionOptions = [{label: "CREATE", value: "CREATE"}, {
    label: "UPDATE",
    value: "UPDATE"
  }, {label: "DELETE", value: "DELETE"}, {
    label: "LOGIN",
    value: "LOGIN"
  }, {
    label: "GET",
    value: "GET"
  }]

  const levelOptions = [{label: "EMERGENCY", value: 0}, {
    label: "ALERT", value: 1
  }, {label: "CRITICAL", value: 2}, {label: "ERROR", value: 3}, {
    label: "WARNING", value: 4
  }, {label: "NOTICE", value: 5}, {label: "INFO", value: 6}, {label: "DEBUG", value: 7},]

  const levelFilter = ref(null)
  const resourseOptions = [{label: "user", value: "USER"}]
  const resourseFilter = ref(null)

  const change = computed(() => store.getters['user/getChangeTraslate'])

  let tableColumns = [
    {label: 'log_microservice.difference', key: 'diff', sortable: true},
    {label: 'log_microservice.resource', key: 'resource', sortable: true},
    {label: 'log_microservice.user_id', key: 'user_id', sortable: true},
    {label: 'log_microservice.client_host', key: 'client_host', sortable: true},
    {label: 'log_microservice.provider', key: 'provider', sortable: true},
    {label: 'log_microservice.level', key: 'level', sortable: true},
    {label: 'log_microservice.log_message', key: 'log_message.message', sortable: true},
    {label: 'log_microservice.description', key: 'description', sortable: true},
    {label: 'log_microservice.action', key: 'action', sortable: true},
    {label: 'log_microservice.tenant_id', key: 'tenant_cat', sortable: true},
    {
      label: 'date.created_at',
      key: 'created_at',
      sortable: true,
      formatter: 'formatDateAssigned'
    }
  ];

  let indexToInsert = tableColumns.findIndex(column => column.key === 'provider') + 1;

  if (userData.profile.type === "MasterAdmin") {
    tableColumns.splice(indexToInsert, 0,
      {label: 'log_microservice.message', key: 'message', sortable: true},
      {label: 'log_microservice.path', key: 'path', sortable: true}
    );
  }

  const perPage = ref(10)
  const totalList = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalList.value,
    }
  })

  const handleCreateAction = (objeto) => {
    return {
      ...objeto,
      diff: {
        diff: null,
        data: JSON.parse(objeto.data),
        action: 'CREATE',
        resource: objeto.resource,
      },
    };
  }

  const handleDeleteAction = (objeto) => {
    return {
      ...objeto,
      diff: {
        diff: null,
        data: JSON.parse(objeto.old_data),
        action: 'DELETE',
        resource: objeto.resource,
      },
    };
  }

  const handleDefaultAction = (objeto) => {
    if (objeto.data === '[]' && objeto.old_data === '[]') {
      return {
        ...objeto,
        diff: {
          diff: null,
          data: JSON.parse(objeto.data),
          old_data: JSON.parse(objeto.old_data),
          resource: objeto.resource
        },
      };
    } else {
      const diferencias = deepDiff(JSON.parse(objeto.data), JSON.parse(objeto.old_data));
      return {
        ...objeto,
        diff: {
          diff: diferencias,
          old_data: JSON.parse(objeto.old_data),
          resource: objeto.resource,
        },
      };
    }
  }

  const fetchList = (ctx, callback) => {
    const addHoursToDateString = (dateString) => {
      if (dateString != null) {
        const date = parse(dateString, "yyyy-MM-dd HH:mm", new Date());
        const addDate = addHours(date, 6);
        return format(addDate, "yyyy-MM-dd'T'HH:mm:ss");
      }
      return null;
    };

    let addEndDate = addHoursToDateString(endDate.value);
    let addStartDate = addHoursToDateString(startDate.value);

    store.dispatch('microservice_logs/getAll', {
      q: searchQuery.value,
      max: perPage.value,
      'page[n]': currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      message: messageFilter.value,
      user_id: userFilter.value,
      tenant_id: tenantFilter.value,
      action: actionFilter.value,
      level: levelFilter.value,
      start_at: addStartDate,
      end_at: addEndDate,
      resource: resourseFilter.value
    })
      .then(response => {
        let newData = response.data;
        newData = newData.map(objeto => {
          switch (objeto.action) {
            case 'CREATE':
              return objeto.old_data === '[]' ? handleCreateAction(objeto) : objeto;
            case 'DELETE':
              return objeto.data === '[]' ? handleDeleteAction(objeto) : objeto;
            default:
              return handleDefaultAction(objeto);
          }
        });
        callback(newData);
        totalList.value = response.total
      })
      .catch((error) => {
        console.log(error)
        toast({
          component: ToastificationContent, props: {
            title: 'Error obteniendo la información',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const formatDateAssigned = value => {
    const formattedDate = moment(value).format('DD/MM/YYYY HH:mm:ss')
    return formattedDate
  }

  const refetchData = () => {
    refListTable.value.refresh()
  }

  watch([resourseFilter, startDate, endDate, levelFilter, actionFilter, tenantFilter, userFilter, messageFilter, currentPage, perPage, searchQuery, change], () => {
    refetchData()
  })

  return {
    resourseOptions,
    resourseFilter,
    startDate,
    endDate,
    levelOptions,
    levelFilter,
    actionFilter,
    actionOptions,
    tenantOptions,
    tenantFilter,
    userOptions,
    userFilter,
    messageOptions,
    messageFilter,
    tableColumns,
    perPage,
    currentPage,
    totalList,
    fetchList,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refListTable,
    formatDateAssigned,
    refetchData,
    change
  }
}
